import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactLensesCatalogData } from '../../model/exam'
import { ContactLensesState } from '../../model/model'

const initialState: ContactLensesState = {
	catalog: [],
}

export type SetSameProductBothEyesEnabledData = {
	tabId: number
	enabled: boolean
}

export const slice = createSlice({
	name: 'contactLenses',
	initialState,
	reducers: {
		_loadContactLensesCatalog: (
			state,
			{ payload }: PayloadAction<ContactLensesCatalogData[]>,
		) => {
			state.catalog = payload
		},
	},
})

export default slice.reducer
